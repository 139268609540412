import React, { useState, createContext } from "react";
  
// export const TabTicketContext = createContext();

const TabTicketContext = createContext();


// export const TabTicketProvider= ({ children }) => {

//     const [tabOption, setTabOption] = useState(0);
  
//     return (
//         <TabTicketContext.Provider value={{ tabOption, setTabOption }}>
//             {children}
//         </TabTicketContext.Provider>
//     );
// };




const TabTicketProvider = ({ children }) => {

    const [tabOption, setTabOption] = useState(0);
  
    return (
        <TabTicketContext.Provider value={{ tabOption, setTabOption }}>
            {children}
        </TabTicketContext.Provider>
    );
};

export { TabTicketContext, TabTicketProvider };