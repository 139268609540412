const rules = {
  user: {
    static: [],
  },

  supervisor: {
    static: [
      "menu-users:view",
      "user-view:show",
      "user-modal:editQueues",
      'dashboard-view:show',
      'ticket-report:show',
      'tickets-manager:showall'
    ]
  },


  admin: {
    static: [
      'show-icon-add-queue',
      'show-icon-edit-whatsapp',
      'show-icon-edit-queue',
      'menu-users:view',
      'drawer-admin-items:view',
      'tickets-manager:showall',
      'user-modal:editProfile',
      'user-modal:editQueues',
      'ticket-options:deleteTicket',
      'contacts-page:deleteContact',
      'contacts-page:import-csv-contacts',
      'connections-view:show',
      'dashboard-view:show',
      'queues-view:show',
      'user-view:show',
      'ticket-report:show',
      'btn-add-user'
    ],
  },

  master: {
    static: [
      'menu-users:view',
      'url-remote-session:show',
      'show-icon-edit-whatsapp',
      'show-icon-add-queue',
      'show-icon-edit-queue',
      'show-icon-delete-queue',
      'space-disk-info:show',
      'drawer-admin-items:view',
      'tickets-manager:showall',
      'user-modal:editProfile',
      'user-modal:editQueues',
      'ticket-options:deleteTicket',
      'contacts-page:deleteContact',
      'contacts-page:import-contacts',
      'contacts-page:import-csv-contacts',
      'connections-view:show',
      'dashboard-view:show',
      'queues-view:show',
      'user-view:show',
      'settings-view:show',
      'btn-add-user',
      'icon-remove-user',
      'btn-add-whatsapp',
      'btn-remove-whatsapp',
      'ticket-report:show',
      'connection-button:show',
    ],
  },
}

export default rules
