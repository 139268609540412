import React, { useState, useEffect } from 'react'
import Routes from './routes'
import 'react-toastify/dist/ReactToastify.css'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'

import { TabTicketProvider } from '../src/context/TabTicketHeaderOption/TabTicketHeaderOption'

const App = () => {
  const [locale, setLocale] = useState()

  const theme = createTheme(
    {
      scrollbarStyles: {
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',

          backgroundColor: '#e8e8e8',
        },
      },
      palette: {
        //primary: { main: "#2576d2" },
        primary: { main: '#ec5114' },
      },
    },
    locale
  )

  useEffect(() => {
    const i18nlocale = localStorage.getItem('i18nextLng')
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5)

    if (browserLocale === 'ptBR') {
      setLocale(ptBR)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {/*TabTicketProvider Context to manipulate the entire state of selected option from user click on tickets options header */}
      <TabTicketProvider>
        <Routes />
      </TabTicketProvider>
    </ThemeProvider>
  )
}

export default App
